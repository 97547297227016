﻿.o-dialog {
    top: 120px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 40px 30px 35px;
    width: 325px;
    border-radius: 6px;
    background-color: #FFFFFF;
    border: 0;

    &--wide {
        width: 100%;
        max-width: 980px;
    }

    &[open] {
        display: flex;
    }

    img {
        margin-bottom: 26px;
    }

    p:not(.h2) {
        max-width: 100%;
        margin-bottom: 30px;
        font-size: 16px;
        line-height: (19/16);
    }

    &__buttons {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;

        .o-btn {
            flex-basis: 0;
            margin-right: 10px;
            margin-left: 10px;
            padding: 10px;
            min-width: 88px;
            font-size: 12px;
            line-height: (18/12);
            margin-bottom: 0;
        }
    }

    &__close {
        position: absolute;
        top: 0;
        right: 0;
        padding: 20px;
        font-size: 30px;
        line-height: 0.7;
        border: 0;
        background-color: transparent;
        cursor: pointer;
    }

    form {
        width: 100%;
    }

    .o-field {
        margin-bottom: 40px;
        width: 100%;
    }
}

dialog::backdrop {
    background-color: rgba(0,0,0,0.8);
    z-index: 100;
}

// Polyfill CSS
dialog {
    position: fixed;
    left: 0;
    right: 0;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: -webkit-fit-content;
    height: fit-content;
    margin: auto;
    border: solid;
    padding: 1em;
    background: white;
    color: black;
    display: block;
}

dialog:not([open]) {
    display: none;
}

dialog + .backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,0.8);
    z-index: 100;
}

._dialog_overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

dialog.fixed {
    position: fixed;
    top: 50%;
    transform: translate(0, -50%);
}