﻿.c-navigation {
    display: none;
    position: absolute;
    width: 300px;
    background-color: white;
    top: 90px;
    bottom: 0;
    left: 100vw;
    height: calc(100vh - 90px);
    opacity: 0;
    transition: left 200ms ease-in-out, opacity 200ms ease;
    z-index: 10;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    @media screen and (min-width:0\0) {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
    }

    &.is-display-flex {
        display: flex;
    }

    &.is-active {
        left: calc(100vw - 300px);
        opacity: 1;
    }

    @media (min-width: 992px) {
        display: flex;
        position: relative;
        left: auto;
        top: auto;
        width: auto;
        height: 100%;
        background-color: transparent;
        opacity: 1;
        overflow: visible;
    }

    > ul {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0;
        padding: 0;
        list-style: none;

        @media (min-width: 992px) {
            flex-direction: row;
            justify-content: center;
        }

        > li {
            position: relative;
            padding: 0 0 14px;
            border-bottom: 1px solid #E8EAEC;

            @media (min-width: 992px) {
                padding: 0;
                border-bottom: 0;
            }

            @media screen and (min-width:0\0) {
                display: inline-block;
            }

            &:hover, &:focus {
                @media (min-width: 992px) {
                    > ul {
                        display: block;
                    }

                    > a:not(.o-btn):before {
                        @media (min-width: 992px) {
                            content: "";
                            display: block;
                            position: absolute;
                            bottom: -1px;
                            right: 10px;
                            left: 10px;
                            height: 4px;
                            background-color: $brand-primary;
                        }
                    }

                    > a:not(.o-btn):after {
                        transform: rotate(-135deg);
                        margin-top: 4px;
                    }
                }
            }

            &.is-active {
                > a:not(.o-btn) {
                    &:before {
                        @media (min-width: 992px) {
                            content: "";
                            display: block;
                            position: absolute;
                            bottom: -1px;
                            right: 10px;
                            left: 10px;
                            height: 4px;
                            background-color: $brand-primary;
                        }
                    }
                }
            }

            &.is-hidden {
                padding-bottom: 0;

                > a:not(.o-btn) {
                    &:after {
                        top: 15px;
                        transform: rotate(45deg);
                    }
                }

                .o-btn,
                > ul {
                    display: none;
                }
            }

            &.membersArea {
                @media (min-width: 992px) {
                    display: none;
                }
            }

            a:not(.o-btn) {
                width: 100%;
                position: relative;
                display: inline-block;
                font-size: 12px;
                line-height: (18/12);
                padding: 15px 20px;
                color: #333333;
                text-decoration: none;
                cursor: pointer;

                @media (min-width: 992px) {
                    display: inline-flex;
                    align-items: center;
                    padding: 15px 19px;
                    height: 100%;
                    font-size: 14px;
                    line-height: (22/14);
                }

                @media screen and (min-width:0\0) {
                    padding: 33px 20px;
                }

                &:after {
                    content: "";
                    position: absolute;
                    top: 22px;
                    right: 22px;
                    display: inline-block;
                    padding: 5px;
                    border: solid black;
                    border-width: 0 2px 2px 0;
                    transform: rotate(-135deg);

                    @media (min-width: 992px) {
                        position: relative;
                        top: auto;
                        right: auto;
                        margin-left: 16px;
                        margin-top: -4px;
                        border-width: 0 1px 1px 0;
                        padding: 3px;
                        transform: rotate(45deg);
                    }
                }
            }

            .o-btn {
                margin-top: 10px;
                margin-left: 20px;
            }

            > ul {
                flex-direction: column;
                list-style: none;
                margin: 0;
                padding: 0;

                @media (min-width: 992px) {
                    display: none;
                    position: absolute;
                    top: 90px;
                    left: 50%;
                    margin-left: -110px;
                    width: 220px;
                    padding-left: 0;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    background-color: #fff;
                    border: 1px solid #F1F4F9;
                    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
                }

                > li {
                    > a:not(.o-btn) {
                        width: 100%;
                        padding: 5px 5px 5px 30px;

                        @media (min-width: 992px) {
                            padding: 7px;
                            text-align: center;
                            justify-content: center;
                        }

                        &:hover, &:focus {
                            @media (min-width: 992px) {
                                background-color: $brand-primary;
                                color: #fff;
                            }
                        }

                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.c-login {
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;

    > ul {
        display: flex;
        flex-direction: row;
        margin: 0;
        padding: 0;
        list-style: none;

        > li {
            align-items: center;
            display: inline-flex;
            position: relative;

            @media screen and (min-width:0\0) {
                display: table-cell;
                vertical-align: middle;
            }

            &:hover, &:focus {
                > ul {
                    display: block;
                }
            }

            > a:not(.o-btn) {
                position: relative;
                display: none;
                padding: 33px 0 33px 10px;
                text-decoration: none;
                cursor: pointer;

                @media (min-width: 992px) {
                    display: inline-block;
                    height: 100%;
                }

                @media screen and (min-width:0\0) {
                    display: inline-block;
                }

                &:after {
                    content: "";
                    position: relative;
                    top: auto;
                    right: auto;
                    display: inline-block;
                    margin-top: -4px;
                    margin-bottom: 4px;
                    margin-left: 16px;
                    padding: 3px;
                    border: solid black;
                    border-width: 0 1px 1px 0;
                    border-color: $brand-primary;
                    transform: rotate(45deg);
                }
            }

            > ul {
                display: none;
                flex-direction: column;
                margin: 0;
                padding: 20px 0;
                list-style: none;
                position: absolute;
                top: 86px;
                right: 0;
                width: 170px;
                background-color: #fff;
                box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
                border: 1px solid #F1F4F9;
                border-top: 4px solid $brand-primary;

                li {
                    text-align: center;

                    a:not(.o-btn) {
                        display: block;
                        padding: 14px 10px;
                        font-size: 14px;
                        line-height: (22/14);
                        text-align: center;
                        text-decoration: none;
                        color: #333333;
                        cursor: pointer;

                        &:hover, &:focus {
                            background-color: $brand-primary;
                            color: #fff;
                        }
                    }

                    .o-btn {
                        margin-top: 10px;
                        margin-right: auto;
                        margin-left: auto;
                    }
                }
            }
        }
    }

    &__submenu {
        > a:after {
            content: "";
            position: relative;
            top: auto;
            right: auto;
            display: inline-block;
            margin-top: -4px;
            margin-bottom: 4px;
            margin-left: 16px;
            padding: 3px;
            border: solid black;
            border-width: 0 1px 1px 0;
            border-color: $brand-primary;
            transform: rotate(45deg);
        }

        > ul {
            list-style: none;
            padding-left: 0px;
            > li {
                > a {
                    color: red;
                }
            }
        }
    }
}