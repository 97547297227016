﻿.o-btn {
    display: inline-flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    position: relative;
    width: auto;
    min-width: 110px;
    padding: 13px 32px;
    font-size: 12px;
    font-weight: 700;
    text-decoration: none;
    line-height: (18/12);
    text-align: center;
    white-space: normal;
    border: 1px solid $brand-primary;
    border-radius: 4px;
    background-color: $brand-primary;
    color: white;
    overflow: hidden;
    cursor: pointer;

    @media screen and (min-width:0\0) {
        display: inline-block;
        vertical-align: middle;
    }

    &[disabled] {
        opacity: 0.75;
        cursor: not-allowed;

        &:hover, &:focus {
            background-color: $brand-primary;
        }
    }

    &:hover, &:focus {
        background-color: $brand-secondary;
        border-color: $brand-secondary;
    }

    &--outline {
        border: 1px solid $brand-primary;
        background-color: transparent;
        color: $brand-primary;

        &:hover, &:focus {
            color: #fff;
        }
    }

    &--ghost {
        border: 0;
        background-color: transparent;
        color: $brand-primary;

        &:hover, &:focus {
            color: #fff;
            border: 1px solid $brand-secondary;
        }
    }

    &--help-panel {
        position: fixed;
        right: 0;
        bottom: 10vh;
        min-width: initial;
        width: 60px;
        height: 60px;
        padding: 0;
        background-color: #FFFFFF;
        border: 0;
        box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
        border-radius: 50% 0 0 50%;
        z-index: 10;

        @media (min-width: 992px) {
            display: none;
        }

        &:hover, &:focus {
            background-color: #fff;
        }

        img {
            &.active {
                display: none;
            }
        }

        &.is-active {
            img {
                &.inactive {
                    display: none;
                }

                &.active {
                    display: block;
                }
            }
        }
    }
}
