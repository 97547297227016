﻿.c-header {
    position: relative;
    height: 90px;
    border-bottom: 1px solid #D8D8D8;
    background-color: #fff;
    z-index: 10;

    @media (min-width: 992px) {
        box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
    }

    .o-container {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: space-between;

        .ie9 & {
            display: table;
        }
    }

    &__logo {
        display: inline-block;

        .ie9 & {
            display: table-cell;
            vertical-align: middle;
        }
    }

    &__right {
        display: flex;
        align-items: center;
        height: 100%;

        .ie9 & {
            display: table-cell;
            vertical-align: middle;
            text-align: right;
        }

        .o-btn {
            margin-right: 10px;

            @media (min-width: 375px) {
                margin-right: 20px;
            }

            @media (min-width: 992px) {
                margin-right: 0;
            }
        }
    }
}

.c-toggle {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    cursor: pointer;

    @media (min-width: 992px) {
        display: none;
    }

    &__bar {
        position: relative;
        width: 22px;
        height: 3px;
        right: 0;
        background-color: gray;
        margin-top: 2px;
        margin-bottom: 2px;
        transition: all 200ms ease-in-out;

        &:nth-child(1) {
            transform-origin: top right;
        }

        &:nth-child(3) {
            transform-origin: bottom right;
        }
    }

    &.is-active {
        .c-toggle__bar:nth-child(1) {
            transform: rotate(-45deg);
            width: 23px;
            right: 5px;
        }

        .c-toggle__bar:nth-child(2) {
            opacity: 0;
        }

        .c-toggle__bar:nth-child(3) {
            transform: rotate(45deg);
            width: 23px;
            right: 5px;
            bottom: -1px;
        }
    }
}