html {
    font-size: 1em;
}

body {
    font-size: $base-size-rem;
    font-family: $font-stack;
    line-height: (24/14);
    color: $text-colour;
    overflow-x: hidden;
    background-color: #F1F4F9;

    &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        pointer-events: none;
        background-color: rgba(74, 74, 74, 0.75);
        opacity: 0;
        transition: opacity 200ms ease;

        @media (min-width: 992px) {
            display: none;
        }
    }

    &.is-nav-active {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        &:after {
            opacity: 1;
        }
    }

    &.is-panel-active {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        &:after {
            opacity: 1;
        }

        .c-header {
            z-index: 0;
        }

        .c-main {
            z-index: initial;
        }
    }
}

img {
    max-width: 100%;
    height: auto;
}