
$base-size:                 14px;
$base-size-rem:             (14px/16px) * 1rem;
$max-width:                 1100px;
$grid-gutter:               20px;

$system-font-stack:         -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-stack:                "Tahoma", $system-font-stack;
$heading-font-stack:        $font-stack;

$text-colour:               #454D58;
$error-colour:              #C3003C;
$brand-primary:             #009696;
$brand-secondary:           #005F6E;
$brand-light-grey:          #9B9B9B;