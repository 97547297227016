﻿figure {
    margin: 0;
}

fieldset {
    padding: 0;
}

%g-margin-bottom,
h1, h2, h3, h4, h5, h6,
ul, ol, dl,
blockquote, p, address,
hr,
/*button,*/
table,
fieldset,
figure,
pre,
.aligncenter {
    margin-top: 0;
    margin-bottom: $base-size;
}

dd {
    margin-top: 0;
    margin-bottom: $base-size / 2;
}

img {
    height: auto;
    max-width: 100%;
    vertical-align: middle;
}

[hidden] {
    display: none !important;
}
