﻿﻿.c-products {
    @extend .c-quick-links;

    @media (min-width: 992px) {
        margin-top: 0;
    }
}

.c-product-intro {
    padding-top: 75px;
    padding-bottom: 40px;

    @media (min-width: 992px) {
        padding-top: 60px;
        padding-bottom: 0;
    }

    .o-flag {
        &__image {
            display: none;

            @media (min-width: 992px) {
                display: block;
                margin-right: 90px;
                overflow: hidden;
            }

            img {
                @media (min-width: 992px) {
                    max-width: 460px;
                    margin-bottom: -60px;
                }
            }
        }

        &__body {
            @media (max-width: 991px) {
                text-align: center;
            }
        }
    }
}

.c-product-benefits {
    padding-top: 60px;
    padding-bottom: 60px;

    h2 {
        text-align: center;
        margin-bottom: 65px;
    }

    &__list {
        max-width: 720px;
        margin-right: auto;
        margin-left: auto;

        .o-flag {

            @media (min-width: 992px) {
                margin-bottom: 40px;
            }

            @media (max-width: 991px) {
                flex-direction: column;
                margin-bottom: 20px;
            }

            &:last-of-type {
                margin-bottom: 0;
            }

            &__image {
                margin-bottom: 30px;

                @media (min-width: 992px) {
                    width: 160px;
                    margin-right: 60px;
                    margin-bottom: 0;
                }

                img {
                    @media (min-width: 992px) {
                        width: 100%;
                    }
                }
            }
        }
    }
}
