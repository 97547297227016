﻿.c-section {
    background-color: #fff;

    .c-tabs & {
        background-color: transparent;
    }

    &--login {
        background-color: #f1f4f9;

        @media (min-width: 992px) {
            display: grid;
            grid-template-columns: 2fr 3fr;
            min-height: 80vh;
        }

        @media (min-width: 1500px) {
            min-height: 874px;
        }

        .c-section__login {
            padding: 50px 24px;

            @media (min-width: 992px) {
                display: flex;
                justify-content: center;
                align-items: center;
                grid-column: 1;
            }

            @media (min-width: 1200px) {
                align-items: flex-start;
                padding-top: 80px;
                padding-bottom: 80px;
            }

            @media (min-width: 1500px) {
                padding-top: 138px;
                padding-bottom: 138px;
            }
        }

        .c-section__image {
            display: none;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;

            @media (min-width: 992px) {
                position: relative;
                display: block;
                grid-column: 2;
            }

            @media (min-width: 1500px) {
                min-height: 874px;
            }

            img {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: left center;
            }
        }
    }

    &--article-with-aside {
        @media (min-width: 992px) {
            padding-top: 50px;
            padding-bottom: 80px;
        }

        > .o-container {
            @media (min-width: 992px) {
                display: grid;
                grid-gap: 20px;
                grid-template-columns: 1fr 280px;
            }

            @media screen and (min-width:0\0) {
                grid-template-columns: 1fr 20px 280px;
            }

            .ie9 & {
                &::after {
                    display: block;
                    content: "";
                    clear: both;
                }
            }

            > * {
                &:nth-child(1) {
                    @media screen and (min-width:0\0) {
                        grid-column: 1;
                    }

                    .ie9 & {
                        float: left;
                        width: 780px;
                    }
                }

                &:nth-child(2) {
                    @media screen and (min-width:0\0) {
                        grid-column: 3;
                    }

                    .ie9 & {
                        float: left;
                        width: 280px;
                        margin-left: 20px;
                    }
                }
            }
        }
    }

    &--two-columns {
        .o-container {
            @media (min-width: 600px) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
            }

            @media (min-width: 600px) and (max-width: 991px) {
                grid-gap: 20px !important;
            }

            @media (min-width: 992px) {
                grid-gap: 60px;
            }

            @media screen and (min-width:0\0) {
                grid-template-columns: 1fr 60px 1fr;
            }

            .ie9 & {
                &::after {
                    display: block;
                    content: "";
                    clear: both;
                }
            }

            > * {
                .ie9 & {
                    float: left;
                    width: 510px;
                }

                &:nth-child(1) {
                    @media screen and (min-width:0\0) {
                        grid-column: 1;
                    }

                    .ie9 & {
                        margin-right: 60px;
                    }
                }

                &:nth-child(2) {
                    @media screen and (min-width:0\0) {
                        grid-column: 3;
                    }
                }
            }
        }
    }

    &--three-columns {
        .o-container {
            @media (min-width: 992px) {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-gap: 80px;
            }

            @media screen and (min-width:0\0) {
                grid-template-columns: 1fr 80px 1fr 80px 1fr;
            }

            .ie9 & {
                &::after {
                    display: block;
                    content: "";
                    clear: both;
                }
            }

            > * {
                .ie9 & {
                    float: left;
                    width: 306px;
                }

                &:nth-child(1) {
                    @media screen and (min-width:0\0) {
                        grid-column: 1;
                    }

                    .ie9 & {
                        margin-right: 80px;
                    }
                }

                &:nth-child(2) {
                    @media screen and (min-width:0\0) {
                        grid-column: 3;
                    }

                    .ie9 & {
                        margin-right: 80px;
                    }
                }

                &:nth-child(3) {
                    @media screen and (min-width:0\0) {
                        grid-column: 5;
                    }
                }
            }
        }
    }

    &--error {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        /*min-height: 80vh;*/
        padding-top: 32px;
        padding-bottom: 32px;

        @media (min-width: 992px) {
            padding-top: 80px;
            padding-bottom: 80px;
        }

        @media (min-width: 1200px) {
            padding-top: 130px;
            padding-bottom: 130px;
        }

        .o-container {
            max-width: 100%;
            /*display: flex;
            justify-content: center;
            align-items: center;*/
        }
    }
}

.c-title-subtitle {
    padding-top: 60px;
}

.c-article {
    .c-banner {
        height: auto;
        margin-bottom: 37px;

        .o-container {
            padding: 0;

            > *:last-child {
                margin-bottom: 0;
            }
        }
    }

    > *:last-child {
        @media (min-width: 992px) {
            margin-bottom: 0;
        }
    }
}

.c-aside {
    @media (min-width: 992px) {
        margin-top: 20px;
    }
}
