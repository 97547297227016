﻿.c-footer {
    &__upper {
        background-color: #6D767E;

        @media (min-width: 992px) {
            padding-top: 37px;
            padding-bottom: 37px;
        }

        .o-container {
            display: flex;
            flex-direction: column;
            padding-right: $grid-gutter / 2;
            padding-left: $grid-gutter / 2;

            @media (min-width: 992px) {
                flex-direction: row;
                justify-content: space-between;
            }

            @media screen and (min-width:0\0) {
                display: table;
            }
        }
    }

    &__lower {
        padding: 30px 0;
        background-color: #383F44;
        text-align: center;

        .o-container {
            justify-content: center;
        }

        p {
            margin-bottom: 0;
            font-size: 12px;
            line-height: (14/12);
            color: #fff;
        }
    }

    &__nav {
        width: 100%;
        margin: 0;
        padding: 0;
        list-style: none;

        @media (min-width: 992px) {
            display: flex;
        }

        @media screen and (min-width:0\0) {
            display: table-cell;
        }

        > li {
            width: 100%;
            padding: 20px;
            border-bottom: 1px solid #fff;

            @media (min-width: 992px) {
                width: auto;
                flex-basis: 200px;
                padding: 0;
                border-bottom: none;
            }

            @media screen and (min-width:0\0) {
                display: inline-block;
                width: 200px;
                float: left;
            }

            > a {
                display: block;
                padding: 4px 0 8px;
                font-size: 12px;
                font-weight: 700;
                line-height: (18/12);
                color: #fff;
                text-decoration: none;
            }

            > ul {
                margin: 0;
                padding: 0;
                list-style: none;
                border-bottom: 0;

                > li {
                    > a {
                        display: block;
                        padding: 4px 0;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: (18/12);
                        color: #fff;
                        text-decoration: none;
                    }
                }
            }
        }
    }

    &__logo {
        padding: 30px 20px;

        @media (min-width: 992px) {
            padding: 0;
        }

        @media screen and (min-width:0\0) {
            display: table-cell;
            vertical-align: top;
        }
    }
}
