﻿fieldset {
    margin: 0;
    border: 0;
}

.umbraco-forms-form {
    padding-bottom: 40px;
}

.umbraco-forms-page {
    @media (min-width: 992px) {
        display: flex;
        flex-wrap: wrap;
    }

    .ie9 & {
        &::after {
            display: block;
            content: "";
            clear: both;
        }
    }
}

.umbraco-forms-navigation {
    clear: both;
    flex-basis: 100%;
    text-align: center;
}

.umbraco-forms-fieldset {
    &:first-of-type {
        @media (min-width: 992px) {
            flex-basis: 328px;
            margin-right: 92px;
        }

        .ie9 & {
            float: left;
            width: 328px;
            margin-right: 92px;
        }
    }

    &:nth-of-type(2) {
        @media (min-width: 992px) {
            flex-basis: calc(100% - 328px - 98px);
        }

        .ie9 & {
            float: left;
            width: calc(100% - 328px - 98px);
        }
    }
}

.umbraco-forms-field {
    margin-bottom: 21px;
}

.o-fieldset {
    padding: 20px 10px;
    background-color: #fff;

    @media (min-width: 992px) {
        margin-right: -25px;
        margin-left: -25px;
    }

    > .o-field {
        padding-right: 13px;
        padding-left: 13px;

        &:not(:first-of-type) {
            padding-top: 20px;
        }

        &:not(:last-of-type) {
            margin-bottom: 0;
            padding-bottom: 20px;
            border-bottom: 1px solid #E8EAEC;
        }

        .o-label {
            margin-bottom: 1.5rem;
            font-size: 16px;
            line-height: (19/16);
            color: $text-colour;
        }

        &.is-invalid {
            > .o-label {
                color: $error-colour;
            }
        }
    }

    .o-field-group {
        margin-bottom: 0;

        .o-field {
            label {
                margin-bottom: 4px;
                font-size: 12px;
                color: $brand-primary;
            }

            &.is-invalid {
                > .o-label {
                    color: $error-colour;
                }
            }
        }
    }
}

.o-field-group {
    display: flex;
    flex-direction: row;
    margin-bottom: 21px;
    margin-right: -5px;
    margin-left: -5px;

    > .o-field {
        margin-bottom: 0 !important;
        flex-grow: 1;
        flex-basis: 0;
        padding-right: 5px;
        padding-left: 5px;

        .o-field__lookup,
        .o-field__reset {
            right: 5px;
        }
    }

    + hr {
        margin-top: 20px;
    }
}

.o-field {
    position: relative;
    margin-bottom: 21px;
    line-height: 1;
    text-align: left;
    flex-shrink: 1;

    &--single {
        display: inline-flex;

        @media (min-width: 992px) {
            margin-right: 2px;
            margin-left: 2px;
        }

        &:first-of-type {
            margin-left: 0;
        }

        &:last-of-type {
            margin-right: 0;
        }
    }

    &--disabled {
        background-color: #EDEDED;

        .o-label {
            color: #333333;
        }

        .o-input {
            background-color: #EDEDED;
            border-bottom-color: #4A4A4A;
            cursor: not-allowed;
        }
    }

    &--small {
        max-width: 120px;
        flex-shrink: 0;
    }

    &.is-invalid {
        > .o-label {
            color: $error-colour;
        }

        > .o-input {
            border-color: $error-colour;
        }
    }

    .dropdown & {
        position: relative;


        &:after {
            content: "";
            position: absolute;
            top: 31px;
            right: 10px;
            display: inline-block;
            padding: 3px;
            border: solid $brand-primary;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
    }

    &__lookup,
    &__reset {
        position: absolute;
        right: 0;
        bottom: -20px;
        font-size: 12px;
        font-weight: 700;
        line-height: (18/12);
        text-decoration: none;
    }
}

.o-label {
    position: relative;
    display: block;
    margin-top: -2px;
    padding-top: 4px;
    margin-bottom: 2px;
    color: $brand-primary;
    font-size: 12px;
    line-height: (18/12);

    &__optional {
        color: $brand-light-grey;
        position: absolute;
        right: 0;
    }
}

.o-input {
    width: 100%;
    padding: 4px 0;
    font-size: 14px;
    line-height: (22/14);
    font-family: $font-stack;
    border: 0;
    border-bottom: 1px solid $brand-primary;

    &--single {
        width: 48px;
        height: 48px;
        font-size: 30px;
        line-height: 1;
        background-color: #fff;
        border: 2px solid $brand-primary;
        border-radius: 5px;
        text-align: center;
        -moz-appearance: textfield;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
        }
    }
}

select.o-input {
    -webkit-appearance: none;

    &::-ms-expand {
        display: none;
    }
}

.field-validation-valid {
    display: block;
    margin-top: 4px;
    font-size: 12px;
    line-height: (14/12);
    text-align: right;
    color: $error-colour;
}

.c-form {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;

    @media (min-width: 992px) {
        max-width: 330px;
        align-items: flex-start;
        text-align: left;
    }

    form {
        width: 100%;
    }

    &__large {
        margin-top: calc(75px - 16px);
        margin-bottom: 75px;

        .o-field--single {
            margin-bottom: 0;
        }

        p {
            margin-bottom: 0;
        }
    }

    &__buttons {
        display: flex;
        margin-top: 50px;
        justify-content: center;

        @media (min-width: 992px) {
            justify-content: flex-start;
        }

        .o-btn {
            margin-right: 20px;
        }
    }

    &__error-image {
        margin-bottom: 27px;
    }

    p {
        max-width: 100%;

        &.lead {
            margin-bottom: 30px;
        }
    }

    .g-recaptcha {
        > div {
            margin: auto;

            @media (min-width: 992px) {
                margin: 0;
            }
        }
    }

    .o-input:not(.o-input--single) {
        background-color: transparent;
    }

    &__helplink {
        display: block;
        margin-top: 20px;
        font-size: 12px;
        line-height: (18/12);
        font-weight: 700;
        text-align: center;
        text-decoration: none;

        @media (min-width: 992px) {
            text-align: left;
        }
    }
}

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
    background: $brand-primary !important;
    border-color: $brand-primary !important;
}