﻿.c-error {
    width: 100%;
    max-width: 320px;
    margin-right: auto;
    margin-left: auto;
    padding: 45px;
    text-align: center;
    border-radius: 6px;
    background-color: #FFFFFF;
    box-shadow: 0 0 6px 0 rgba(0,0,0,0.12);

    @media (min-width: 992px) {
        max-width: 400px;
    }

    p {
        max-width: 210px;
        margin-right: auto;
        margin-bottom: 28px;
        margin-left: auto;

        &.lead {
            margin-bottom: 20px;
        }
    }

    img {
        margin-bottom: 20px;
    }
}
