﻿body.debug {
    * {
        outline: 1px dashed rgba(red, 0.1);

        > * {
            outline: 1px dashed rgba(red, 0.2);

            > * {
                outline: 1px dashed rgba(red, 0.3);

                > * {
                    outline: 1px dashed rgba(red, 0.4);

                    > * {
                        outline: 1px dashed rgba(red, 0.5);

                        > * {
                            outline: 1px dashed rgba(red, 0.6);

                            > * {
                                outline: 1px dashed rgba(red, 0.7);

                                > * {
                                    outline: 1px dashed rgba(red, 0.8);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
