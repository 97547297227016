﻿.c-quick-links {
    padding-top: 32px;

    @media (min-width: 992px) {
        margin-top: -26px;
        padding-top: 0;
    }

    > p.lead {
        margin-bottom: 32px;

        @media (min-width: 992px) {
            margin-bottom: 20px;
        }
    }
}