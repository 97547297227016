﻿.o-flag {
    display: flex;
    align-items: baseline;

    &--center {
        align-items: center;
    }

    &--inline {
        margin-right: auto;
        margin-bottom: 16px;
        margin-left: auto;
        display: inline-flex;
        align-items: center;
        white-space: nowrap;

        .o-flag__image {
            @media (max-width: 991px) {
                flex-shrink: 1;
            }
        }
    }

    &__image {
        flex-shrink: 0;
        margin-right: 8px;

        @media (min-width: 375px) {
            margin-right: 12px;
        }

        @media (min-width: 1200px) {
            margin-right: 20px;
        }

        img {
            height: auto;
        }
    }

    &__body {
        flex-shrink: 1;

        > * {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
