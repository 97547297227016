﻿.c-banner {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
/*    height: 230px;*/
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .o-container {
        position: relative;

        @media (max-width: 991px) {
            padding-right: 20px;
            padding-left: 20px;
        }

        .ie9 & {
            padding-top: 50px;
        }
    }

    h1 {
        padding-top: 40px;
        font-size: 32px;
        line-height: (39/32);
        font-weight: 400;

        @media (min-width: 992px) {
            padding-top: 0;
            margin-bottom: 20px;
            font-size: 36px;
            font-weight: 700;
            line-height: (43/36);
        }
    }

    p {
        max-width: 800px;
        /*font-size: 18px;
        line-height: (28/18);*/
    }

    &--image {
        height: 440px;

        @media (min-width: 992px) {
            height: 340px;
        }

        h1 {
            padding-top: 0;
            max-width: 150px;
            font-size: 30px;
            line-height: (36/30);

            @media (min-width: 992px) {
                max-width: 300px;
                margin-bottom: 40px;
                font-size: 48px;
                line-height: (58/48);
            }
        }

        p {
            max-width: 150px;
            font-size: 18px;
            line-height: (27/18);

            @media (min-width: 992px) {
                max-width: 300px;
                font-size: 20px;
                line-height: (22/20);
            }
        }
    }

    &--members {
        height: 156px;
        background: $brand-secondary;
        background: linear-gradient(0deg, $brand-primary 0%, $brand-secondary 100%);

        h1 {
            margin-bottom: 0;
            padding-top: 0;
            color: #fff;
            font-size: 24px;
            font-weight: 400;
            line-height: (28/24);
        }

        p {
            color: #fff;
            font-size: 14px;
            line-height: (22/14);
        }
    }

    &--product {
        align-items: stretch;

        .o-container {
            padding-top: 55px;

            @media (min-width: 992px) {
                padding-top: 48px;
            }

            > p {
                max-width: 100%;
                color: #fff;
                text-align: center;

                @media (max-width: 991px) {
                    font-size: 16px;
                    line-height: (19/16);
                }
            }
        }

        .logo {
            height: 26px;
            margin-bottom: 12px;

            @media (min-width: 992px) {
                height: 48px;
                margin-bottom: 16px;
            }
        }
    }

    &__login {
        position: absolute;
        bottom: -24px;
        left: 50%;
        margin-left: calc(-17.5rem / 2);
        width: 280px;
        padding: 35px;
        background-color: #fff;
        border-radius: 6px;
        box-shadow: 0 0 6px 0 rgba(0,0,0,0.12);

        @media (min-width: 992px) {
            width: 370px;
            right: 0;
            bottom: -63px;
            left: auto;
        }

        .o-flag {
            @media (min-width: 992px) {
                align-items: center;
            }
        }

        .o-flag__image {
            display: none;

            @media (min-width: 992px) {
                display: block;
                margin-right: 35px;
            }
        }

        .o-flag__body {
            text-align: center;
            flex-grow: 1;

            @media (min-width: 992px) {
                text-align: left;
            }

            p {
                max-width: 100%;

                @media (min-width: 992px) {
                    margin-bottom: 20px;
                }
            }
        }
    }
}
