﻿.c-panels {
    display: flex;
    width: 100%;

    .c-panel {
        text-align: left;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 0;
        margin-bottom: 0;
        padding-top: 0;
        padding-right: 25px;
        padding-bottom: 0;
        padding-left: 25px;

        .o-dialog & {
            p {
                font-size: 14px;
                line-height: (22/14);
            }
        }
    }
}

.c-panel {
    margin-bottom: 40px;

    p:last-child {
        margin-bottom: 0;
    }

    &--aside {
        position: fixed;
        right: 0;
        bottom: calc(10vh + 40px);
        width: 280px;
        padding: 42px 20px;
        border-radius: 2px;
        background-color: #FFFFFF;
        box-shadow: 0 0 6px 0 rgba(0,0,0,0.12);
        transform: translateX(280px);
        z-index: 10;

        &.is-active {
            transform: translateX(0);
        }

        @media (min-width: 992px) {
            position: relative;
            bottom: auto;
            display: block;
            width: auto;
            padding: 40px 20px;
            transform: none;
        }

        h2 {
            margin-bottom: 38px;
        }
    }

    &--rte {
        padding: 40px 0;

        .c-section--article-with-aside & {
            .o-container {
                padding: 0;
            }
        }

        .c-article & {
            padding: 40px 20px;
            background-color: #fff;
            border-radius: 5px;
            box-shadow: 0 0 6px 0 rgba(0,0,0,0.12);

            @media (min-width: 992px) {
                border-radius: 0;
                margin-right: 0;
                margin-left: 0;
                padding: 40px;
            }

            .template-contentPage & {
                margin-right: -20px;
                margin-left: -20px;
                border-radius: 0;
                box-shadow: none;

                @media (min-width: 992px) {
                    margin-right: 0;
                    margin-left: 0;
                    box-shadow: 0 0 6px 0 rgba(0,0,0,0.12);
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        /*h2 {
            font-size: 24px;
            line-height: (29/24);
        }*/
        /*p.lead {
            font-size: 16px;
            line-height: (19/16);
        }*/
    }

    &--product {
        padding: 20px;

        @media (min-width: 992px) {
            margin-bottom: 0;
            padding: 60px 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }

        .c-panel__body {
            position: relative;

            @media (min-width: 992px) {
                display: flex;
                flex-grow: 1;
                flex-shrink: 1;
                flex-basis: 100%;
                max-width: 100%;
                flex-direction: column;
                align-items: flex-start;
            }

            _:-ms-input-placeholder, :root & {
                flex-basis: auto;
            }

            .c-section--full & {
                @media (min-width: 992px) {
                    flex-basis: 100%;
                }
            }
        }

        .c-panel__image {
            flex-shrink: 0;
            max-width: 100%;
            margin-bottom: 40px;
            text-align: center;
        }

        .logo {
            margin-bottom: 16px;
        }

        p {
            flex-grow: 1;
            max-width: 100%;
            font-size: 14px;
            line-height: (22/14);
        }

        .c-section--full & {
            margin-bottom: 0;

            @media (min-width: 992px) {
                max-width: 880px;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;
                margin: auto;
            }

            .c-panel__image {
                @media (min-width: 992px) {
                    margin-right: 110px;
                    margin-bottom: 0;
                    flex-shrink: 0;
                    max-width: 340px;
                }
            }

            .c-panel__body {
                .logo {
                    @media (min-width: 992px) {
                        height: 32px;
                        margin-bottom: 32px;
                    }
                }

                p {
                    @media (min-width: 992px) {
                        margin-bottom: 32px;
                    }
                }
            }

            &-reverse {
                @media (min-width: 992px) {
                    flex-direction: row-reverse;
                }

                .c-panel__image {
                    @media (min-width: 992px) {
                        margin-right: 0;
                        margin-left: 110px;
                    }
                }
            }
        }

        .o-flag {
            display: inline-block;
            padding: 0px;
            width: 100%;
            text-align: center;

            &__image {
                margin-right: 0px;

                img {
                    max-width: 80px;

                    @media screen and (min-width:0\0) {
                        max-width: 100%;
                    }

                    _:-ms-input-placeholder, :root .selector {
                        max-width: 100%;
                    }
                }
            }


            &__body {
                text-align: left;
                margin-top: 10px;
                padding: 0px 20px 12px 20px;

                p {
                    text-align: center;
                }
            }

            &__benefits {
                background-color: #f1f4f9;
                text-align: left;
                padding: 5px 20px 0px 20px;

                ul {
                    list-style-type: none;
                    padding: 0px;

                    li {
                        padding-bottom: 3px;
                        font-size: 11px;

                        img {
                            display: inline-block;
                            max-width: 12px;
                            max-height: 12px;
                            margin-right: 6px;
                            object-fit: scale-down;
                        }
                    }
                }
            }

            &__footer {
                padding: 0px 20px 20px 20px;

                a {
                    text-decoration: none;
                    font-weight: 600;
                    margin: 0px 10px 0px 10px;
                }
            }
        }
    }

    &--icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 20px 0;
        padding: 30px 20px;
        background-color: #fff;
        box-shadow: 0 0 6px 0 rgba(0,0,0,0.12);
        border-radius: 2px;

        @media (min-width: 992px) {
            flex-direction: row;
            align-items: center;
            margin: 60px 0;
            padding: 30px 40px;
        }

        .c-tabs & {
            @media (min-width: 992px) {
                margin-top: 0;
            }
        }

        &.c-panel--icon-top {
            @media (min-width: 992px) {
                align-items: flex-start;
            }

            .c-panel__image {
                @media (min-width: 992px) {
                    align-self: flex-start;
                }
            }
        }

        &.c-panel--icon-bottom {
            @media (min-width: 992px) {
                align-items: flex-end;
            }

            .c-panel__image {
                @media (min-width: 992px) {
                    align-self: flex-end;
                }
            }
        }

        .ie9 & {
            display: table;
        }

        .c-panel__image {
            margin-bottom: 18px;
            width: 80px;
            flex-shrink: 0;
            align-self: center;

            @media (min-width: 992px) {
                width: 100px;
                margin-right: 40px;
                margin-bottom: 0;
            }

            .template-homePage & {
                @media (max-width:991px) {
                    display: none;
                }
            }

            .ie9 & {
                display: table-cell;
            }
        }

        .c-panel__content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: 100%;
        }

        h3 {
            font-size: 20px;
            line-height: (30/20);
        }

        p {
            max-width: 100%;
            font-size: 16px;
            line-height: (22/16);
        }
    }

    &--quick-links {
        @media (max-width: 991px) {
            margin-bottom: 20px;
        }

        @media (min-width: 992px) {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding: 40px;
            background-color: #fff;
            box-shadow: 0 0 6px 0 rgba(0,0,0,0.12);
        }

        @media screen and (min-width:0\0) {
            &:after {
                content: "";
                display: table;
                clear: both;
            }
        }

        &:not([data-count="1"]) {
            @media (min-width: 992px) {
                padding: 40px 20px 10px;
            }

            .c-panel__link {
                @media (min-width: 992px) {
                    flex-grow: 0;
                    flex-shrink: 1;
                    flex-basis: calc(50% - 40px);
                    margin-left: 20px;
                    margin-right: 20px;
                    margin-bottom: 30px;
                }

                &:not(:last-of-type) {
                    @media (max-width: 991px) {
                        margin-bottom: 20px;
                    }
                    /*@media (min-width: 992px) {
                        flex-grow: 1;
                    }*/
                }

                @media screen and (min-width:0\0) {
                    float: left;
                    width: calc(50% - 40px);
                }

                _:-ms-input-placeholder, :root & {
                    flex-basis: calc(50% - 100px);
                }
            }
        }

        &[data-count="1"] {
            .c-panel__link {
                @media (min-width: 992px) {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                .o-flag {
                    @media (min-width: 992px) {
                        margin-bottom: 0;
                    }
                }
            }

            .o-field {
                width: 100%;
                padding: 12px;
            }
        }

        .c-panel__link {
            flex-grow: 1;
            text-align: center;
            background-color: #FFFFFF;
            border-radius: 5px;
            box-shadow: 0 0 6px 0 rgba(0,0,0,0.12);

            &--profile {
                display: flex;
                flex-direction: column;
                text-align: left;
            }

            .o-btn {
                margin-top: 18px;
                margin-right: 12px;
                font-weight: 500;
            }

            .o-flag {
                display: inline-block;
                padding: 0px;
                width: 100%;

                &__image {
                    margin-right: 0px;

                    img {
                        max-width: 80px;

                        @media screen and (min-width:0\0) {
                            max-width: 100%;
                        }

                        _:-ms-input-placeholder, :root .selector {
                            max-width: 100%;
                        }
                    }
                }


                &__body {
                    text-align: left;
                    margin-top: 10px;
                    padding: 0px 20px 12px 20px;

                    p {
                        text-align: center;
                    }
                }

                &__benefits {
                    background-color: #f1f4f9;
                    text-align: left;
                    padding: 5px 20px 0px 20px;

                    ul {
                        list-style-type: none;
                        padding: 0px;

                        li {
                            padding-bottom: 3px;
                            font-size: 11px;

                            img {
                                display: inline-block;
                                max-width: 12px;
                                max-height: 12px;
                                margin-right: 6px;
                                object-fit: scale-down;
                            }
                        }
                    }
                }

                &__footer {
                    padding: 0px 20px 20px 20px;

                    a {
                        text-decoration: none;
                        font-weight: 600;
                    }
                }
            }
        }
    }

    &--my-product {
        margin-right: -20px;
        margin-left: -20px;
        padding: 40px 30px;
        background-color: #fff;

        @media (min-width: 992px) {
            box-shadow: 0 0 6px 0 rgba(0,0,0,0.12);
            border-radius: 0;
            margin-right: 0;
            margin-left: 0;
            padding: 40px;
        }

        .logo {
            margin-bottom: 24px;
            height: 30px;
        }

        .o-flag {
            @media (max-width: 991px) {
                flex-direction: row;
            }

            &__image {
                margin-right: 0;
                margin-bottom: 30px;
                text-align: center;

                @media (min-width: 992px) {
                    margin-right: 62px;
                    margin-bottom: 0;
                }

                .ie9 & {
                    margin-right: 62px;
                }

                img {
                    max-width: 200px;
                }
            }
        }
    }

    &__edit {
        text-align: left;
        padding: 12px 12px 0px 12px;
        margin-bottom: 12px;

        &:not(:last-of-type) {
            padding: 12px 12px 0px 12px;
        }

        label {
            display: block;
            /* margin-bottom: 8px;*/
            font-size: 12px;
            line-height: (18/12);
        }

        p {
            font-size: 18px;
            line-height: (27/18);
            overflow-wrap: break-word;
            word-wrap: break-word;
            -ms-word-break: break-all;
            /* This is the dangerous one in WebKit, as it breaks things wherever */
            word-break: break-all;
            /* Instead use this non-standard one: */
            word-break: break-word;
            -webkit-hyphens: auto;
            -ms-hyphens: auto;
            -moz-hyphens: auto;
            hyphens: auto;
        }

        &--last {
            display: inline-flex;
            flex-grow: 1;
            align-items: flex-end;

            p {
                max-width: 100%;
                text-align: left;
                font-size: 12px;
                line-height: (18/12);
            }
        }
    }

    &__buttons {
        display: flex;

        .o-btn {
            &:not(:first-of-type) {
                margin-left: 20px;

                @media (min-width: 992px) {
                    margin-left: 30px;
                }
            }
        }
    }

    &__button {
        flex-grow: 1;
        flex-basis: 100%;
        text-align: center;

        &--top {
            padding-top: 0;
            padding-bottom: 30px;

            @media (min-width: 992px) {
                display: none;
            }
        }

        &--bottom {
            padding-top: 20px;
            padding-bottom: 20px;

            @media (min-width: 992px) {
                padding-top: 10px;
                padding-bottom: 30px;
            }
        }
    }

    &__flag {
        display: flex;
        align-items: flex-start;
        margin-bottom: 18px;

        @media screen and (min-width:0\0) {
            display: table;
            vertical-align: top;
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        &__icon {
            margin-right: 18px;
            flex-shrink: 0;

            @media screen and (min-width:0\0) {
                display: table-cell;
                padding-right: 18px;
            }
        }

        &__body {
            flex-grow: 1;

            @media screen and (min-width:0\0) {
                display: table-cell;
            }

            p {
                margin-bottom: 0;

                &.large {
                    font-size: 20px;
                    line-height: (28/20);
                    color: $brand-primary;
                }

                a {
                    display: block;
                    color: $brand-primary;
                }
            }
        }
    }
}
