﻿.c-tabs {
    &__switcher {
        display: flex;
        flex-direction: column;
        padding: 0;
        list-style: none;
        margin-right: -20px;
        margin-left: -20px;

        @media (min-width: 992px) {
            flex-direction: row;
            justify-content: center;
            margin-right: 0;
            margin-bottom: 40px;
            margin-left: 0;
            border-bottom: 4px solid #E8EAEC;
        }

        @media (min-width: 1200px) {
            margin-right: 40px;
            margin-bottom: 40px;
            margin-left: 40px;
        }

        li {
            background-color: rgba(255,255,255,0.5);

            @media (min-width: 992px) {
                flex: 0 0 220px;
                margin-right: 10px;
                margin-bottom: -4px;
                margin-left: 10px;
                border-bottom: 4px solid #C6CACF;
                background-color: transparent;
            }

            a {
                max-width: 220px;
                margin: auto;
                display: block;
                padding: 23px 7px;
                text-align: center;
                border-bottom: 4px solid #C6CACF;

                @media (min-width: 992px) {
                    border-bottom: 0;
                }
            }

            &.is-active {
                background-color: #fff;

                @media (min-width: 992px) {
                    background-color: transparent;
                    border-bottom-color: $brand-primary;
                }

                a {
                    border-bottom-color: $brand-primary;
                }
            }
        }
    }

    &__panel {
        display: none;

        &.is-active {
            display: block;
        }

        details {
            margin-bottom: 10px;
            padding: 30px 30px 0;
            border-radius: 2px;
            background-color: #FFFFFF;
            box-shadow: 0 0 6px 0 rgba(0,0,0,0.12);

            &[open] {
                padding: 30px;

                summary {
                    &:after {
                        margin-top: -10px;
                        transform: rotate(45deg);
                    }
                }
            }

            summary {
                list-style: none;
                position: relative;
                padding: 30px;
                margin: -30px;
                font-size: 16px;
                line-height: (19/16);
                cursor: pointer;
                outline: 0;

                &:before {
                    display: none !important;
                }

                &:after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    margin-top: -4px;
                    right: 35px;
                    display: inline-block;
                    padding: 5px;
                    border: solid $brand-primary;
                    border-width: 0 2px 2px 0;
                    transform: rotate(-135deg);
                }

                &::-webkit-details-marker {
                    display: none;
                }
            }

            > *:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}
