﻿.u-text-center {
    text-align: center;
}

/** umb_name: Brand Colour */
.u-colour-brand {
    color: $brand-primary;
}

/** umb_name: Dark Grey */
.u-colour-dark-grey {
    color: $text-colour;
}

/** umb_name: Light Grey */
.u-colour-light-grey {
    color: $brand-light-grey;
}

.u-spinner {
    animation-name: spin;
    animation-direction: normal;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    animation-duration: 1.5s;
    animation-timing-function: linear;
}