﻿.o-container {
    width: 100%;
    max-width: $max-width;
    margin-right: auto;
    margin-left: auto;
    padding-right: $grid-gutter;
    padding-left: $grid-gutter;

    @media (min-width: 992px) {
        padding-right: ($grid-gutter / 2);
        padding-left: ($grid-gutter / 2);
    }

    &--center {
        @media (min-width: 992px) {
            display: flex;
            justify-content: center;
            text-align: center;
        }
    }
}

.o-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -$grid-gutter;
    margin-left: -$grid-gutter;

    @media (min-width: 992px) {
        margin-right: -($grid-gutter / 2);
        margin-left: -($grid-gutter / 2);
    }

    @media screen and (min-width:0\0) {
        &::after {
            display: block;
            content: "";
            clear: both;
        }
    }
}

[class^="o-col-"] {
    width: 100%;

    @media (min-width: 992px) {
        width: auto;
        padding-right: ($grid-gutter / 2);
        padding-left: ($grid-gutter / 2);
    }

    @media screen and (min-width:0\0) {
        float: left;
        width: 100%;
    }
}

@media (min-width: 992px) {
    .o-col-lg-3 {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 25%;
        max-width: 25%;
    }

    .o-col-lg-4 {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 33.33333%;
        max-width: 33.33333%;
    }

    .o-col-lg-6 {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 50%;
        max-width: 50%;
    }

    .o-col-lg-9 {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 75%;
        max-width: 75%;
    }
}

@media (min-width: 1200px) {
    .o-col-lg-3 {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(280px + 20px);
        max-width: calc(280px + 20px);

        @media screen and (min-width:0\0) {
            max-width: 25%;
        }
    }

    .o-col-lg-9 {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(780px + 20px);
        max-width: calc(780px + 20px);

        @media screen and (min-width:0\0) {
            max-width: 75%;
        }
    }
}