a {
    color: $brand-primary;
    text-decoration: underline;
}

b,
strong {
    font-weight: 700;
}

/** umb_name: Paragraph */
p {
    font-size: (14/16) * 1rem;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: 1rem;
    font-weight: 400;
    font-family: $heading-font-stack;
    color: $text-colour;
}

@mixin h1 {
    font-size: 36px;
    line-height: (43/36);
    color: $brand-secondary;

    @media (min-width: 992px) {
        font-weight: 700;
    }
}

/** umb_name: Heading 1 */
h1 {
    @include h1;
}

.h1 {
    @include h1;
}

@mixin h2 {
    font-size: 24px;
    line-height: (29/24);

    @media (min-width: 992px) {
        font-size: 30px;
        line-height: (36/30);
    }
}
/** umb_name: Heading 2 */
h2 {
    @include h2;
}

.h2 {
    @include h2;
}


@mixin h3 {
    font-size: 22px;
    line-height: (27/22);

    @media (min-width: 992px) {
        font-size: 24px;
        line-height: (29/24);
    }
}
/** umb_name: Heading 3 */
h3 {
    @include h3;
}

.h3 {
    @include h3;
}


/** umb_name: Lead */
p.lead {
    font-size: 18px;
    line-height: (27/18);
}

/** umb_name: Small */
p.small {
    font-size: 13px !important;
    line-height: (20/13) !important;
}

/** umb_name: Extra Small */
p.extra-small {
    font-size: 12px;
    line-height: (18/12);
}

ul, ol {
    padding-left: 16px;

    li {

    }
}

hr {
    width: 100%;
    border: 0;
    border-bottom: 1px solid $text-colour;
}

.headline {
    padding: 42px 23px;

    @media (min-width: 992px) {
        padding: 0 0 36px;
    }

    h1 {
        margin-bottom: 0;
    }
}